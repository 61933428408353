import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading,"height":'380'}},[_c(VAlert,{staticClass:"mb-0 pb-2",attrs:{"border":"top","colored-border":"","color":"secondary"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VIcon,{staticClass:"ml-1",attrs:{"size":"30","color":"#01579B"}},[_vm._v("mdi-laptop")]),_c('strong',[_vm._v(" Avisos de cursos ")]),_c(VChip,{staticClass:"elevation-2",attrs:{"small":"","outlined":"","label":"","color":"#01579B"}},[_c(VIcon,{staticClass:"mr-3",attrs:{"left":"","small":""}},[_vm._v("mdi-bell-alert")]),_vm._v(_vm._s(_vm.avisosOrdenados.length))],1)],1)]),_c(VDivider),(_vm.avisosOrdenados.length === 0)?_c(VCardSubtitle,[_vm._v("No hay avisos disponibles")]):_c(VVirtualScroll,{attrs:{"items":_vm.avisosOrdenados,"item-height":63,"height":310},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c(VCard,{staticClass:"d-flex align-center",attrs:{"outlined":"","flat":"","tile":""}},[_c(VListItem,{style:(item.avisoVisto?'max-width: 90%':'max-width: 100%'),on:{"click":function($event){return _vm.avisoSelected(item)}}},[_c(VListItemAvatar,{staticClass:"elevation-2"},[_c(VAvatar,{attrs:{"size":"50","color":"secondary"}},[(!item.cursoCampus.icon)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-book-open-outline")]):_c('img',{attrs:{"src":(_vm.imageURL + "/" + (item.cursoCampus.icon)),"alt":"cursoImage"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.titulo))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.cursoCampus.nombre))])],1)],1),(item.avisoVisto)?_c(VBtn,{staticClass:"ml-2",attrs:{"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.ocultarAviso(item)}}},[_c(VIcon,[_vm._v("mdi-close-circle-outline")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }