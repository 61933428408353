<template>
<div>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-alert border="top" colored-border type="info" elevation="2">
          <strong> Bienvenido al campus de AGNUS </strong><br />
          Selecciona una opción del menú izquierdo.
        </v-alert>
      </v-col>
      <!-- <v-col cols="12">
        <cursosCarouselComponent />
      </v-col>
      <v-col md="6" sm="12">
        <actividadesPendientesComponent />
      </v-col> -->
      <v-col v-if="cuentaAlumno === 'alumno'" v-show="mostrarAvisos" md="4" sm="4">
        <cursosAvisosComponent 
          @showAvisos="(value) => mostrarAvisos = value"
        />
      </v-col>

      <v-col v-show="mostrarAvisosMaster" md="4" sm="4">
        <avisosMasterComponent 
          @showAvisosMaster="mostrarAvisosMaster = true"
        />
      </v-col>

      <v-col v-show="mostrarAvisosEscuela" md="4" sm="4">
        <avisosEscuelaComponent 
          @showAvisosEscuela="mostrarAvisosEscuela = true"
        />
      </v-col>

    </v-row>
  </v-container>
  <tipo-sesion-item
    :mostrar="mostrar"
    :sesion="tipoSesion"
    :titulo="'Indica con que tipo de usuario deseas navegar'"
    @cancelar="mostrar = false"
    @sesionModificada="sesionModificada"
  />
  </div>
</template>

<script>
// import cursosCarouselComponent from "./cursosCarousel.vue";
// import actividadesPendientesComponent from "./actividadesPendientes.vue";
import cursosAvisosComponent from "../globals/template/cursos/avisos/dashboard/cursosAvisos.vue";
import tipoSesionItem from '../perfil/configuracion/modificarTipoSesion.vue'
import avisosMasterComponent from "../globals/template/avisosDashboard/avisosMaster.vue";
import avisosEscuelaComponent from "../globals/template/avisosDashboard/avisosEscuela.vue";
import { mapGetters } from "vuex";
export default {
  name: "dashboardView",
  components: {
    //   cursosCarouselComponent,
    //   actividadesPendientesComponent,
    cursosAvisosComponent,
    tipoSesionItem,
    avisosMasterComponent,
    avisosEscuelaComponent
  },
  computed: {
    ...mapGetters(["tipoSesion", "sessionToken"]),
    cuentaAlumno() {
      let usuario = '';
      if(this.tipoSesion.includes('alumno'))
        usuario = 'alumno';
      return usuario;
    }
  },
  data() {
    return {
      mostrar:false,
      activaError: false,
      mostrarAvisos: false,
      mostrarAvisosMaster: false,
      mostrarAvisosEscuela: false,
    };
  },
   watch:{
    '$store.getters.tipoSesion':function(tipo){
      if(tipo.length==0)this.mostrar=true;
    }
   },
  
  methods:{
    sesionModificada(data) {
      this.mostrar = false;
      this.$store.dispatch("setTipoSesion", data.tipoSesion);
    },
  }
};
</script>
