<template>
  <v-card :loading="loading" :height="'380'">
    
    <v-alert border="top" colored-border color="secondary" class="mb-0 pb-2">
      <div class="d-flex justify-space-between">
        <v-icon class="ml-1" size="30" color="#01579B">mdi-laptop</v-icon>
        <strong> Avisos de cursos </strong>
        <v-chip class="elevation-2" small outlined label color="#01579B"><v-icon left small class="mr-3">mdi-bell-alert</v-icon>{{ avisosOrdenados.length }}</v-chip>
      </div>
    </v-alert>

    <v-divider></v-divider>
    <v-card-subtitle v-if="avisosOrdenados.length === 0">No hay avisos disponibles</v-card-subtitle>
    <v-virtual-scroll v-else
      :items="avisosOrdenados"
      :item-height="63"
      :height="310"
    >
      <template #default="{ item }">
        <v-card outlined class="d-flex align-center" flat tile>
          <v-list-item @click="avisoSelected(item)" :style="item.avisoVisto?'max-width: 90%':'max-width: 100%'">
            <v-list-item-avatar class="elevation-2">
              <v-avatar size="50" color="secondary">
                <v-icon color="white" v-if="!item.cursoCampus.icon">mdi-book-open-outline</v-icon>
                <img v-else :src="`${imageURL}/${item.cursoCampus.icon}`" alt="cursoImage"/>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.titulo}}</v-list-item-title>
              <v-list-item-subtitle>{{item.cursoCampus.nombre}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-btn v-if="item.avisoVisto" icon x-small color="primary" class="ml-2" @click="ocultarAviso(item)"><v-icon >mdi-close-circle-outline</v-icon></v-btn>
        </v-card>
      </template>
  </v-virtual-scroll>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getCursosAvisos, ocultarAvisoVisto } from "../avisos.service.js";

export default {
  name: 'cursosAvisos',
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
    avisosOrdenados() {
      let avisos = [];
      avisos = [...this.avisos].reverse();
      return avisos;
    }
  },
  data () {
    return {
      loading: false,
      avisos: [],
    }
  },
  watch: {
    sessionToken() {
      this.cargarCursosAvisos();
    }
  },
  mounted() {
    if(this.sessionToken) this.cargarCursosAvisos();
  },
  methods: {
    async cargarCursosAvisos() {
      try {
        this.loading = true;
        const serverResponse = await getCursosAvisos();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.avisos = serverResponse.cursosAvisos;
          if(this.avisos.length != 0) this.$emit('showAvisos', true);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    avisoSelected(aviso) {
      this.$router.push(`/curso/campus/${aviso.cursoCampus._id}#avisos`);
    },
    async ocultarAviso(aviso) {
      try {
        this.loading = true;
        const idCurso = aviso.cursoCampus._id;
        const idAvisoVisto = aviso.avisoVisto.idAvisoVisto;
        const data = { ocultar: true };
        const serverResponse = await ocultarAvisoVisto(idCurso, idAvisoVisto, data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if(serverResponse.ocultar) {
            const index = this.avisos.findIndex((a) => a._id == aviso._id);
            if(index >= 0) this.avisos.splice(index, 1);
            if(this.avisos.length == 0) this.$emit('showAvisos', false);
            if(this.avisos.length != 0) this.$emit('showAvisos', true);
          }
        }
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>