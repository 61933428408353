<template>
  <v-card :loading="loading" :height="380" >
    <v-alert border="top" colored-border color="secondary" class="mb-0 pb-2">

      <div class="d-flex justify-space-between">
        <v-icon class="ml-1" size="30" color="#01579B">mdi-alpha-m-circle-outline</v-icon>
        <strong> Avisos de master</strong>
        <v-chip class="elevation-2" small outlined label color="#01579B"><v-icon left small class="mr-3">mdi-bell-alert</v-icon>{{ avisosOrdenados.length }}</v-chip>
      </div>

    </v-alert>
    <v-divider></v-divider>
    <v-card-subtitle v-if="avisosOrdenados.length === 0">No hay avisos disponibles</v-card-subtitle>
    <v-virtual-scroll v-else
      :items="avisosOrdenados"
      :item-height="110"
      :height="310"
      >
      <template #default="{ item }">
        <v-list-item class="listAviso">
          <v-list-item-avatar class="elevation-2">
            <v-avatar size="50" color="secondary">
              <v-icon color="white">mdi-bullhorn</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{item.titulo}}</v-list-item-title>
            <p class="textFecha">{{ moment(item.fechaCreacion).locale('es').format('DD [de] MMMM [de] YYYY') }}</p>
            <p class="textAviso">{{item.descripcion}}</p>
          </v-list-item-content>
        </v-list-item>
      </template>
  </v-virtual-scroll>
  
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { getAvisosMasterService } from "./avisosDashboard.service.js";

export default {
  name: 'avisosMaster',
  props: {},
  computed: {
    ...mapGetters(["sessionToken"]),
    avisosOrdenados() {
      let avisos = [];
      avisos = [...this.avisos].reverse();
      return avisos;
    }
  },
  data () {
    return {
      loading: false,
      avisos: []
    }
  },
  watch: {
    sessionToken() {
      this.cargarAvisosMaster();
    }
  },
  mounted() {
    if(this.sessionToken) this.cargarAvisosMaster();
  },
  created() {
    this.moment = moment;
  },
  methods: {
    async cargarAvisosMaster() {
      try {
        this.loading = true;

        const serverResponse = await getAvisosMasterService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.avisos = serverResponse.avisos;
          if(this.avisos.length != 0) this.$emit('showAvisosMaster');
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>

<style>

.listAviso {
  border-top: 1px solid #ECEFF1;
}
.textFecha {
  font-size: 13px;
  color: #424242;
}
.textAviso {
  margin-top: 10px;
  text-align: justify;
  font-size: 12px;
  color: #616161;
}
</style>