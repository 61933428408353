import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"loading":_vm.loading,"height":380}},[_c(VAlert,{staticClass:"mb-0 pb-2",attrs:{"border":"top","colored-border":"","color":"secondary"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c(VIcon,{staticClass:"ml-1",attrs:{"size":"30","color":"#01579B"}},[_vm._v("mdi-alpha-m-circle-outline")]),_c('strong',[_vm._v(" Avisos de escuela")]),_c(VChip,{staticClass:"elevation-2",attrs:{"small":"","outlined":"","label":"","color":"#01579B"}},[_c(VIcon,{staticClass:"mr-3",attrs:{"left":"","small":""}},[_vm._v("mdi-bell-alert")]),_vm._v(_vm._s(_vm.avisosOrdenados.length))],1)],1)]),_c(VDivider),(_vm.avisosOrdenados.length === 0)?_c(VCardSubtitle,[_vm._v("No hay avisos disponibles")]):_c(VVirtualScroll,{attrs:{"items":_vm.avisosOrdenados,"item-height":110,"height":310},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{staticClass:"listAviso"},[_c(VListItemAvatar,{staticClass:"elevation-2"},[_c(VAvatar,{attrs:{"size":"50","color":"secondary"}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-bullhorn")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.titulo))]),_c('p',{staticClass:"textFecha"},[_vm._v(_vm._s(_vm.moment(item.fechaCreacion).locale('es').format('DD [de] MMMM [de] YYYY')))]),_c('p',{staticClass:"textAviso"},[_vm._v(_vm._s(item.descripcion))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }